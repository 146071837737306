<template>
	<screen class="donations-report-view" size="large">
		<div
			:style="{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				alignContent: 'center'
			}"
			slot="title"
		>
			<h1>Donations</h1>
			<!-- <ui-label :style="{marginLeft: '15px', paddingBottom: 0}">
                <select >
                    <option>All</option>
                    <option>Succeeded</option>
                    <option>Failed</option>
                </select>
            </ui-label> -->
		</div>
		<template slot="buttons">
			<ui-button @click="handleDownload">Export CSV</ui-button>
		</template>
		<div slot="chart">
			<div class="chart-options">
				<el-date-picker
					v-model="date"
					type="daterange"
					start-placeholder="Start date"
					end-placeholder="End date"
					size="small"
					clearable
					@change="dateChange"
				>
				</el-date-picker>
			</div>
			<chart v-if="graph" :data="graph" prepend="$"></chart>
		</div>
		<el-table slot="table" :data="items" style="width: 100%">
			<el-table-column prop="created_at" label="Date">
				<template slot-scope="scope">
					{{ scope.row.created_at | moment('MM/DD/YYYY') }}
				</template>
			</el-table-column>
			<el-table-column prop="amount" label="Amount">
				<template slot-scope="scope">
					{{ numberFormat(scope.row.amount) }}
				</template>
			</el-table-column>
			<el-table-column
				prop="merchant_match_multiplier"
				label="Is Match"
				width="100"
			>
				<template slot-scope="scope">
					{{ scope.row.merchant_match_multiplier ? 'Yes' : 'No' }}
				</template>
			</el-table-column>
			<el-table-column prop="name" label="Organization">
				<template slot-scope="scope">
					{{ getOrg(scope.row) }}
				</template>
			</el-table-column>
			<el-table-column prop="transaction_count" label="# Transactions">
			</el-table-column>
		</el-table>
		<el-pagination
			@current-change="pageChange"
			:current-page="page"
			:page-size="perPage"
			layout="prev, pager, next, total"
			:total="total"
		>
		</el-pagination>
	</screen>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { get, numberFormat } from '@/utilities'
import table from '@/mixins/table'
import download from '@/mixins/download'
import moment from 'moment'

import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import Screen from '../../ui/Screen'
import Chart from '../../ui/Chart'

export default {
	name: 'donations-report-view',
	components: { UiButton, Screen, Chart, UiLabel },
	mixins: [table, download],
	props: ['startDate', 'endDate'],
	computed: {
		...mapState({
			items: ({ reportList }) => reportList.items,
			total: ({ reportList }) => reportList.total,
			graph: ({ reportList }) => reportList.graph,
			authToken: ({ me }) => me.token
		}),
		...mapGetters(['institution'])
	},
	data() {
		return {
			date: null,
			tableParamKeys: ['startDate', 'endDate'],
			demo: localStorage.getItem('demo') || false
		}
	},
	head: {
		title: {
			inner: 'Reports - Donations'
		}
	},
	methods: {
		...mapActions(['getDonationsReport']),
		navigateToDonorDetail(donation) {
			this.$router.push({
				name: 'donors.detail',
				params: { donorId: donation.user.id }
			})
		},
		paramsChanged(params) {
			this.getDonationsReport(params)
		},
		handleDownload() {
			window.analytics.track('download_donation_report')
			var params = this.getParameters()
			params.perPage = 1000
			this.postParameters('/reports/payouts/csv', params, this.authToken)
		},
		dateChange(dates) {
			let params = this.getParameters()
			if (dates) {
				params.startDate = moment(dates[0]).format('YYYY-MM-DD')
				params.endDate = moment(dates[1]).format('YYYY-MM-DD')
			}
			this.setParameters(params)
		},
		getOrg(row) {
			if (this.demo === 'true') {
				return this.institution && this.institution.name
			}

			return row.name
		},
		numberFormat,
		get
	},
	mounted() {
		this.getDonationsReport(this.getParameters())
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.donations-report-view {
	.el-table__header-wrapper {
		position: relative;

		th {
			.cell {
				font-weight: 600;
			}
		}
	}
}
</style>
