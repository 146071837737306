const formBuilderLoop = function(form, key, item) {
	if (Array.isArray(item) || key == null) {
		for (const subKey in item) {
			if (item.hasOwnProperty(subKey) && item[subKey] != null) {
				let subKeyBuild = ''
				if (key) subKeyBuild += key + '[]'
				else subKeyBuild += subKey
				formBuilderLoop(form, subKeyBuild, item[subKey])
			}
		}
	} else {
		var hiddenField = document.createElement('input')
		hiddenField.setAttribute('type', 'hidden')
		hiddenField.setAttribute('name', key)
		hiddenField.setAttribute('value', item)

		form.appendChild(hiddenField)
	}
}

export default {
	methods: {
		postParameters(path, params, token) {
			params['Authorization'] = 'Bearer ' + token
			let baseURL = process.env.VUE_APP_baseUrl

			const form = document.createElement('form')
			form.setAttribute('method', 'post')
			form.setAttribute('target', '_blank')

			if (localStorage.demo && localStorage.demo !== 'false') {
				const demoRoutes = ['reports', 'donors', 'home']
				const showDemo = demoRoutes.some(rt => path.includes(rt))
				if (showDemo) {
					baseURL = baseURL.replace('merchants', 'demo/merchants')
				}
			}

			form.setAttribute('action', baseURL + path)

			formBuilderLoop(form, null, params)

			document.body.appendChild(form)
			form.submit()
		}
	}
}
